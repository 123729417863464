export default {
  head() {
    return this.meta;
  },
  data() {
    return {
      seoDefault: {
        title: this.$t('seo.default.title'),
        description: this.$t('seo.default.description'),
        robots: true,
      },
    };
  },
  computed: {
    /* eslint-disable complexity */
    seoValues() {
      let currentPath = this.$nuxt.$route.path;
      if (!currentPath.endsWith('/')) {
        currentPath += '/';
      }
      let dynamicDefaults = {
        canonical: this.$config.baseUrl + currentPath,
      };

      let defaults = {
        ...this.seoDefault,
        ...dynamicDefaults,
      };
      if (!this.seo) {
        return defaults;
      }

      let processedValues = {};

      // * Gets all of the values set on the seo object and adds them to processed values
      Object.keys(this.seo).forEach(key => {
        if (this.seo[key]) {
          //Stop Null and empty values overriding
          processedValues[key] = this.seo[key];
        }
      });

      if (this.seo.title && this.seo.title.length > 0) {
        processedValues.title = this.$t('seo.seo_title', {
          title: this.seo.title,
        });
      } else if (this.seo.titleFallback && this.seo.titleFallback.length > 0) {
        processedValues.title = this.$t('seo.title', {
          title: this.seo.titleFallback,
        });
      }

      // * Use seo title, if not there then page title
      if (this.seo.title && this.seo.title.length > 0) {
        processedValues.meta_title = this.$t('seo.seo_title', {
          title: this.seo.title,
        });
      } else if (this.seo.titleFallback && this.seo.titleFallback.length > 0) {
        processedValues.meta_title = this.$t('seo.title', {
          title: this.seo.titleFallback,
        });
      }

      // * Sets description
      if (this.seo.description && this.seo.description.length > 0) {
        processedValues.meta_description = this.seo.description;
      } else if (
        this.seo.descriptionFallback &&
        this.seo.descriptionFallback.length > 0
      ) {
        processedValues.meta_description = this.seo.descriptionFallback;

        // Only add the default description if it is NOT
        // - a Single Blog Post
        // - Blog Category Page
        // - Product Page (PDP)
        // - Product Category Page
      } else if (
        !this.seo.isBlog &&
        !this.seo.isSingleBlogPost &&
        !this.seo.isBlogCategoryPage &&
        !this.seo.isProduct &&
        !this.seo.isCategory
      ) {
        processedValues.meta_description = this.seoDefault.description;
      }

      processedValues.robots = {};
      if (
        typeof this.seo.robots === 'boolean' &&
        !this.seo.robots &&
        typeof this.seo.robotsFollow === 'boolean' &&
        this.seo.robotsFollow
      ) {
        processedValues.robots = {
          follow: 'follow',
          index: 'noindex',
        };
      } else if (typeof this.seo.robots === 'boolean' && !this.seo.robots) {
        processedValues.robots = {
          follow: 'nofollow',
          index: 'noindex',
        };
      } else {
        processedValues.robots = {
          follow: 'follow',
          index: 'index',
        };
      }

      return {
        ...defaults,
        ...processedValues,
      };
    },
    meta() {
      let title = this.seoValues.title;
      let meta_title = this.seoValues.meta_title;
      let meta_description = this.seoValues.meta_description;

      let metadata = {
        title: title,
        meta: [
          {
            hid: 'og_title',
            name: 'title',
            property: 'og:title',
            content: meta_title,
          },
        ],
      };

      if (meta_description) {
        // metadata.meta.push({
        //   hid: 'description',
        //   name: 'description',
        //   content: meta_description,
        // });
        metadata.meta.push({
          hid: 'og_description',
          name: 'description',
          property: 'og:description',
          content: meta_description,
        });
      }

      if (this.seoValues.image) {
        metadata.meta.push({
          hid: 'og_image',
          name: 'image',
          property: 'og:image',
          content: this.seoValues.image,
        });
        metadata.meta.push({
          hid: 'og_image_secure_url',
          name: 'secure-image',
          property: 'og:image:secure_url',
          content: this.seoValues.image,
        });
      }

      if (this.seoValues.canonical) {
        metadata.meta.push({
          hid: 'og_url',
          name: 'url',
          property: 'og:url',
          content: this.seoValues.canonical,
        });
        metadata.link = [
          {
            rel: 'canonical',
            href: this.seoValues.canonical,
          },
        ];
      }

      if (this.seoValues.robots) {
        let robotsContent = '';
        Object.keys(this.seoValues.robots).forEach(key => {
          robotsContent += this.seoValues.robots[key] + ', ';
        });
        robotsContent = robotsContent.slice(0, -2);
        metadata.meta.push({
          hid: 'robots',
          name: 'robots',
          content: robotsContent,
        });
      }

      if (this.seoValues.schema) {
        metadata.script = [];
        const validTypes = ['organisation', 'website', 'product', 'breadcrumb', 'store'];
        validTypes.forEach(type => {
          if (this.seoValues.schema[type]) {
            metadata.script.push({
              type: 'application/ld+json',
              json: this.seoValues.schema[type],
            });
          }
        });
      }

      return metadata;
    },
  },
};
